import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ee-expansion-panel',
  template: `
    <div class="ee__expansion-panel" [ngClass]="{'full-height': fullHeight, 'full-width': fullWidth, 'minimal': theme === 'minimal' }">
        <div class="ee__expansion-panel-header">
          <div class="ee__expansion-panel-header-text">
            {{header}}<span class="required" *ngIf="required">*</span>
          </div>
          <div class="ee__expansion-panel-header-actions">
            <ng-content select="[ee-expansion-panel-header-actions]"></ng-content>
          </div>
          <button *ngIf="collapsible" class="ee__expansion-panel-expander" type="button"
                  mat-icon-button (click)="changeExpandedState()">
            <mat-icon fontSet="fa" [fontIcon]="expanded ? 'fa-chevron-down' : 'fa-chevron-up'"></mat-icon>
          </button>
        </div>
        <div [hidden]="!expanded" [class]="'ee__expansion-panel-body ' + additionalContentClasses" [ngClass]="{'h-full': fullHeight}">
          <ng-content></ng-content>
        </div>
    </div>
  `,
  styles: [
    `
      @import 'components/color-palette';
      .ee__expansion-panel {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        flex: 1;

        &.full-height {
          height: 100%;
        }

        &.full-width {
          width: 100%;
        }

        &.minimal .ee__expansion-panel-header {
          border-bottom: none;
          padding-bottom: 0;

          .ee__expansion-panel-header-text {
            text-transform: none;
            font-weight: 600;
            color: var(--dark-gray-color);
          }
        }

        .ee__expansion-panel-header {
          font-size: 1rem;
          padding: 1rem;
          display: flex;
          flex-direction: row;
          justify-items: flex-start;
          align-items: center;
          border-bottom: 1px solid #d7d7d7;
          max-height: 57px;

          .ee__expansion-panel-header-text {
            flex: 1;
            font-weight: 500;
            text-transform: uppercase;

            .required {
              color: red;
            }

            .disable-msg {
              color: red;
              margin-left: 10px;
              font-size: 12px;
              text-transform: none;
            }
          }

          .ee__expansion-panel-expander {
            height: 24px;
            width: 24px;
            padding: 0;
            color: #6f6f6f;

            .mat-icon {
              line-height: 0.875rem;
              height: 1rem;
              width: 1rem;
              font-size: 1rem;
            }
          }
        }
        .ee__expansion-panel-body {
          padding: 1rem;
          height: 100%;
          overflow: hidden;
          flex: 1;
        }
      }
    `
  ]
})
export class ExpansionPanelComponent {

  @Input() theme: 'standard' | 'minimal' = 'standard';

  @Input() header: string;

  @Input() required = false;

  @Input() expanded = true;

  @Input() fullHeight = false;

  @Input() fullWidth = false;

  @Input() collapsible = true;

  @Input() additionalContentClasses: string;

  @Output() expandedStateChanged = new EventEmitter<boolean>();

  public constructor(private _change: ChangeDetectorRef) {}

  changeExpandedState() {
    this.expanded = !this.expanded;
    this.expandedStateChanged.emit(this.expanded);
  }

  updateExpandedState(state: boolean): void {
    this.expanded = state;
    this._change.markForCheck();
  }
}
