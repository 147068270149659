import {Component, Input} from '@angular/core';

@Component({
  selector: 'ee-loading',
  template: `
    <div class="flex flex-1 flex-col justify-center items-center">
      <div class="app-loader-wrapper">
        <div class="ee-logo">
          <div class="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <span *ngIf="!hideMessage">{{message}}</span>
      </div>
    </div>
  `,
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input()
    message = 'EasyEviction.com';

  @Input() hideMessage = false;
}
