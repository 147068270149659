import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Organization} from '@ee/common/models';
import {Subscription} from 'rxjs';
import {ClientService} from '@ee/common/services';

@Component({
  selector: 'ee-client-select',
  template: `
    <div class="client-select-wrapper flex flex-col justify-stretch items-stretch">
      <mat-form-field class="compact mb-4">
        <mat-label>Search</mat-label>
        <input matInput [(ngModel)]="query" autocomplete="off">
      </mat-form-field>
      <div class="list-wrapper flex flex-row flex-[1_0_100%]" *ngIf="filteredClients?.length; else noClients">
        <mat-list class="searchable-list w-full">
          <mat-list-item *ngFor="let client of filteredClients | search:'company_name,identifier':query"
                         (click)="onSelectionChanged(client)" class="client-row"
                         [ngClass]="{'selected': client === selectedClient}">
            <div class="flex flex-row justify-stretch items-center">
              <div class="primary">{{client.company_name}}</div>
              <div class="secondary">{{client.identifier}}</div>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
      <ng-template #noClients>
        <div class="flex flex-row flex-[1_0_100%] justify-center items-center">
          No clients exist. At least one client must exist to continue.
        </div>
      </ng-template>
    </div>
  `,
  styles: [`
    @import 'components/color-palette';

    :host {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      min-height: 100%;
    }

    .client-select-wrapper {
      overflow-y: hidden;
    }

    .list-wrapper {
      max-height: 200px;
      overflow: auto;
      border: 1px solid $c-gray-90;
    }

    .primary {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      vertical-align: top;
      text-overflow: ellipsis;
    }
  `]
})
export class ClientSelectComponent implements OnInit, OnChanges, OnDestroy {
  filteredClients: Organization[];
  query: string;
  subs: Subscription[] = [];

  @Input() autoSelect = true;
  @Input() initClientId: string;
  @Input() selectedClient: Organization;
  @Input() fields: string;
  @Output() selectedClientChange: EventEmitter<Organization> = new EventEmitter<Organization>();
  @Output() clientCount: EventEmitter<number> = new EventEmitter<number>();

  constructor(private clientService: ClientService) {
  }

  ngOnInit() {
    const fields = ['company_name', 'identifier', 'county_override_rules'];
    if (this.fields?.length) {
      this.fields.split(',').forEach((f) => {
        if (!fields.includes(f)) {
          fields.push(f);
        }
      });
    }
    this.subs.push(this.clientService.searchClientsByCompanyName('', fields)
      .subscribe((clients: Organization[]) => {
        this.clientCount.emit(clients.length);
        this.filteredClients = clients;
        if (this.filteredClients.length && this.autoSelect) {
          if (!this.initClientId || !this.selectInitClient()) {
            this.selectedClient = this.filteredClients[0];
            this.selectedClientChange.emit(this.selectedClient);
          }
        }
      }));
  }

  ngOnChanges() {
    if (this.initClientId && this.filteredClients) {
      this.selectInitClient();
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  onSelectionChanged(client: Organization) {
    this.selectedClient = client;
    this.selectedClientChange.emit(client);
  }

  private selectInitClient(): boolean {
    const result = this.filteredClients.filter(c => c.id === this.initClientId);
    if (result.length) {
      this.onSelectionChanged(result[0]);
      return true;
    }
    return false;
  }

}
