import {Component, Inject, ViewChild} from '@angular/core';
import {BillingFormComponent} from './billing-form.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BillableItemType} from '@ee/common/enums';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GenerateBillableItemForm} from '@ee/common/models';

@Component({
  selector: 'ee-billable-item-editor-dialog',
  template: `
    <h2 mat-dialog-title>{{title}}</h2>

    <form mat-dialog-content class="flex flex-col" [formGroup]="form" (ngSubmit)="save()">
      <ee-billing-form class="flex flex-col" [billingForm]="form" [attorneyFee]="attorneyFee"></ee-billing-form>
    </form>

    <div mat-dialog-actions class="ee-modal-actions">
      <button mat-button type="button" [mat-dialog-close]="null">CANCEL</button>
      <button mat-button type="submit" (click)="save()" cdkFocusInitial color="accent" [disabled]="form.invalid">
        SAVE
      </button>
    </div>
  `
})
export class BillableItemEditorDialogComponent {
  @ViewChild(BillingFormComponent) billingFormComponent: BillingFormComponent;

  form: UntypedFormGroup;
  billableItemType = BillableItemType;
  title = 'Edit Billable Item';
  attorneyFee: number | undefined;

  constructor(private formBuilder: UntypedFormBuilder,
              public dialogRef: MatDialogRef<BillableItemEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = GenerateBillableItemForm(formBuilder, data.billableItem);
    this.attorneyFee = data.attorneyFee;
    // override title only if one is passed in
    if (data.title) {
      this.title = data.title;
    }
  }

  save() {
    if (this.form.get('type').value === this.billableItemType.TIME_ENTRY) {
      this.form.patchValue({amount: this.form.get('qty').value * this.form.get('rate').value});
    } else {
      this.form.patchValue({qty: 1, rate: this.form.get('amount').value});
    }
    this.dialogRef.close(this.form.value);
  }
}
