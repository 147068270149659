import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UserSelectorService} from './user-selector.service';
import {Observable} from 'rxjs';
import {User} from '@ee/common/models';

@Component({
  selector: 'ee-user-selector',
  template: `
    <ng-select
      [items]="userSuggestions$ | async"
      [multiple]="true"
      [closeOnSelect]="false"
      bindLabel="display_name"
      bindValue="id"
      appendTo="body"
      [placeholder]="placeholder"
      [formControl]="$any(selectedUsers)">
      <ng-template ng-multi-label-tmp let-users="items" let-clear="clear">
        @for (user of users; track user) {
          <div class="ng-value">
            <span class="small" [matTooltip]="user.display_name" [matTooltipPosition]="'above'" [matTooltipShowDelay]="500" [matTooltipHideDelay]="1000"
                  [attr.data-letters]="user.display_name | dataLetters" [ngStyle]="{'--user-selector-color': user.color}"></span>
          </div>
        }
      </ng-template>
      <ng-template ng-option-tmp let-user="item">
        <span class="small mr-2" [attr.data-letters]="user.display_name | dataLetters"
              [ngStyle]="{'--user-selector-color': user.color}"></span> {{ user.display_name }}
      </ng-template>
    </ng-select>
  `,
  styles: [
    `
      .ng-value {
        background-color: transparent !important;
        margin: 0 !important;
      }
    `
  ]
})
export class UserSelectorComponent implements OnInit {
  @Input({required: true}) public selectedUsers: FormControl;
  @Input({required: false}) public placeholder: string = 'Assign users...';

  public userSuggestions$: Observable<User[]>;

  constructor(private userSelectorService: UserSelectorService) {
  }

  ngOnInit() {
    this.userSuggestions$ = this.userSelectorService.getUserSuggestions();
  }
}
