<div class="wizard-wrapper w-full h-full flex flex-col flex-1 md:w-[1024px] justify-start items-stretch">
  <mat-stepper #stepper [orientation]="smallScreen ? 'vertical' : 'horizontal'" class="wizard no-scroll-y" [linear]="true"
               (selectionChange)="stepChanged()">
    <mat-step [stepControl]="intakeEvictionFormService.workflowDetails"
              *ngIf="forceIntakeForm || loggedInOrg?.type === attorneyAccount">
      <ng-template matStepLabel>{{firstStepLabel}}</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="intakeEvictionFormService.workflowDetails" class="flex flex-1 flex-col">
          <ee-workflow-form class="mb-4" *ngIf="!forceIntakeForm && loggedInOrg"
                            (addClient)="addClient.emit($event)" (workflowSelected)="selectedWorkflow = $event">
          </ee-workflow-form>
          <ee-new-client-form class="mb-4" *ngIf="forceIntakeForm" [form]="intakeEvictionFormService.workflowDetails"></ee-new-client-form>
          <div class="actions flex flex-row justify-end items-start">
            <button type="button" mat-raised-button color="accent" (click)="goForward(1, stepper)"
                    [disabled]="intakeEvictionFormService.workflowDetails?.invalid">
              Next
            </button>
          </div>
        </form>
      </ng-template>
    </mat-step>
    <mat-step [stepControl]="intakeEvictionFormService.propertyDetails">
      <ng-template matStepLabel>Tenant & Property Details</ng-template>
      <ng-template matStepContent>
        <div class="form-wrapper flex flex-1 flex-col" *ngIf="intakeEvictionFormService.propertyDetails && intakeEvictionFormService.intakeForm">
          <ee-eviction-property-details class="mb-4"
            [options]="intakeEvictionFormService.options" [loggedInOrg]="loggedInOrg" [plaintiffHistory]="intakeEvictionFormService.plaintiffHistory"
            [selectedAttorneyId]="intakeEvictionFormService.attorneyId"
            [selectedClient]="$any(intakeEvictionFormService.workflowDetails?.get('client_details')?.value)"
            [tenantDetailsForm]="intakeEvictionFormService.tenantDetails" [tenantSection]="intakeEvictionFormService.tenantsSection"
            [propertyDetailsForm]="intakeEvictionFormService.propertyDetails" [propertySection]="intakeEvictionFormService.propertySection">
          </ee-eviction-property-details>
          <div class="actions flex flex-row items-start"
               [ngClass]="{
               'justify-end': loggedInOrg?.type !== attorneyAccount,
               'justify-between': (loggedInOrg?.type === attorneyAccount || forceIntakeForm) }">
            <button mat-raised-button (click)="goBack(2, stepper)" type="button"
                    *ngIf="loggedInOrg?.type === attorneyAccount || forceIntakeForm">
              Back
            </button>
            <button mat-raised-button color="accent" type="button" (click)="goForward(3, stepper)">
              Next
            </button>
          </div>
        </div>
      </ng-template>
    </mat-step>
    <mat-step [stepControl]="intakeEvictionFormService.claimDetails">
      <ng-template matStepLabel>Claims & Lease Terms</ng-template>
      <ng-template matStepContent>
        <div class="form-wrapper flex-1 flex flex-col" *ngIf="intakeEvictionFormService.claimDetails && intakeEvictionFormService.intakeForm">
          <ee-expansion-panel class="mb-4" header="Claims" [expanded]="true" [collapsible]="false" [fullWidth]="true">
            <ee-claims-section [form]="intakeEvictionFormService.claimDetails" [claimsSection]="intakeEvictionFormService.claimsSection" [clearOnSwitch]="true"
                               [overrideFieldVisibility]="loggedInOrg?.type === attorneyAccount" [options]="intakeEvictionFormService.options"
                               [evictionReason]="intakeEvictionFormService.evictionReason" [eviction]="intakeEvictionFormService.evictionDetails">
            </ee-claims-section>
          </ee-expansion-panel>
          <ee-expansion-panel class="mb-4" header="Lease Terms" [expanded]="true" [collapsible]="false" [fullWidth]="true">
            <div ee-expansion-panel-header-actions>
              <div style="font-size: 14px">Specify the terms of the lease and other details</div>
            </div>
            <ee-lease-terms-section [form]="intakeEvictionFormService.leaseTermsDetails" [leaseTermsSection]="intakeEvictionFormService.leaseTermsSection"
                                    [overrideFieldVisibility]="loggedInOrg?.type === attorneyAccount"
                                    [options]="intakeEvictionFormService.options">
            </ee-lease-terms-section>
          </ee-expansion-panel>
          <ee-expansion-panel class="mb-4" header="Internal Fields" [expanded]="true" [collapsible]="false" [fullWidth]="true"
                              *ngIf="!!intakeEvictionFormService.internalDetails && intakeEvictionFormService.setupInternalFields">
            <div ee-expansion-panel-header-actions>
              <div style="font-size: 14px">Custom fields only visible to your firm</div>
            </div>
            <ee-internal-section [form]="intakeEvictionFormService.internalDetails" [internalSection]="intakeEvictionFormService.internalSection"
                                 [options]="intakeEvictionFormService.options">
            </ee-internal-section>
          </ee-expansion-panel>
          <div class="actions flex flex-row justify-between items-start">
            <button mat-raised-button (click)="goBack(3, stepper)" type="button">Back</button>
            <button mat-raised-button color="accent" type="button" (click)="goForward(4, stepper)">
              Next
            </button>
          </div>
        </div>
      </ng-template>
    </mat-step>
    <mat-step [stepControl]="intakeEvictionFormService.documents">
      <ng-template matStepLabel>Documentation</ng-template>
      <ng-template matStepContent>
        <div class="form-wrapper flex flex-1 flex-col" *ngIf="currentStep === 4 && intakeEvictionFormService.documents && intakeEvictionFormService.intakeForm">
          <div class="mb-4 flex flex-col flex-[1_1_100%]">
            <ee-eviction-documentation class="mb-4" [currentStep]="currentStep"
              *ngIf="currentStep === 4 && intakeEvictionFormService.documents && intakeEvictionFormService.documentSection && intakeEvictionFormService.claimDetails && intakeEvictionFormService.propertyDetails"
              [documentsForm]="intakeEvictionFormService.documents" [section]="intakeEvictionFormService.documentSection" [workflowForm]="intakeEvictionFormService.workflowDetails"
              [claimForm]="intakeEvictionFormService.claimDetails" [propertyForm]="intakeEvictionFormService.propertyDetails">
            </ee-eviction-documentation>

            <ee-expansion-panel class="mb-4" *ngIf="loggedInOrg?.type !== attorneyAccount" header="Notes"
                                [expanded]="true" [collapsible]="false" [fullWidth]="true">
              <div class="flex flex-row justify-stretch items-stretch" [formGroup]="intakeEvictionFormService.splitForm">
                <mat-form-field class="hide-label-gap flex-1" appearance="fill" floatLabel="auto">
                  <textarea matInput rows="4" style="font-size: 16px" formControlName="note"></textarea>
                  <mat-hint>Notes for the attorney</mat-hint>
                </mat-form-field>
              </div>
            </ee-expansion-panel>

            <ee-expansion-panel class="mb-4" *ngIf="loggedInOrg?.type === attorneyAccount && selectedWorkflow" [header]="selectedWorkflow.name + ': Intake Fields'"
                                [expanded]="true" [collapsible]="false" [fullWidth]="true">
              <div class="w-full flex flex-1 flex-col justify-stretch items-stretch">
                <ee-complete-workflow-step class="flex-1 w-full" *ngIf="intakeEvictionFormService.workflowDetails && intakeEvictionFormService.intakeStep"
                                           [caseSettings]="caseSettings" [nextStep]="intakeEvictionFormService.intakeStep"
                                           [clientId]="intakeEvictionFormService.workflowDetails?.get('client_details')?.value?.id"
                                           [county]="intakeEvictionFormService.propertyDetails?.get('county')?.value"
                                           [tenants]="intakeEvictionFormService.tenants"
                                           [parentForm]="intakeEvictionFormService.splitForm" [hideLabel]="true" [forceVertical]="true"  />
              </div>
            </ee-expansion-panel>
            <div class="flex flex-row justify-end items-end" *ngIf="forceIntakeForm" [formGroup]="intakeEvictionFormService.splitForm">
              <ng-hcaptcha formControlName="captcha"></ng-hcaptcha>
            </div>
          </div>
          <div class="actions flex flex-row justify-between items-start">
            <button mat-raised-button (click)="goBack(4, stepper)" type="button">Back</button>
            <button mat-raised-button type="button" color="accent" [loading]="saving"
                    (click)="save()" matStepperNext [disabled]="saving">
              {{ !forceIntakeForm && (loggedInOrg?.type === attorneyAccount ||
                loggedInOrg?.id === intakeEvictionFormService.attorneyId) ? 'Save' : 'Send'
              }}
            </button>
          </div>
        </div>
      </ng-template>
    </mat-step>
  </mat-stepper>
</div>
