import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TaskType} from '@ee/common/enums';
import {CourtTask, WorkflowStepTask} from '@ee/common/models';
import {CURRENCY} from '@ee/common/constants';

export function generateBaseTaskForm(fb: FormBuilder, type: TaskType, name: string | null = null): FormGroup {
  return fb.group({
    id: new FormControl<string | null>(null),
    case_id: new FormControl<string | null>(null),
    name: new FormControl<string>({value: name, disabled: type === TaskType.WORKFLOW_STEP},
      {validators: [Validators.required], nonNullable: true}),
    description: new FormControl<string | null>({value: null, disabled: type === TaskType.WORKFLOW_STEP}),
    due_date: new FormControl<string | null>(null, type === TaskType.WORKFLOW_STEP || type === TaskType.COURT ? [Validators.required] : []),
    priority: new FormControl<number>(0, [Validators.required]),
    assigned_users: new FormControl<string[]>([]),
    type: new FormControl<TaskType>(type),
    tags: new FormControl<string[]>([]),
    timezone: new FormControl<string | null>(Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone)
  });
}

export function generateCourtTaskForm(fb: FormBuilder, task: CourtTask | null = null, nameOverride: string | null = null,
  requireJudgement = false): FormGroup {
  const form = generateBaseTaskForm(fb, TaskType.COURT, task?.name ?? nameOverride ?? 'Initial Court Date');

  form.addControl('show_time', new FormControl<boolean>(task?.show_time ?? true, requireJudgement ? [Validators.required] : []));
  form.addControl('court_result', new FormControl<string>(task?.court_result));
  form.addControl('legacy', new FormControl<boolean>(task?.legacy));
  form.addControl('legacy_court_time', new FormControl<string>(task?.legacy_court_time));
  form.addControl('judgement', new FormControl<string>(task?.judgement, requireJudgement ? [Validators.required] : []));
  form.addControl('judgement_amount', new FormControl<number>(task?.judgement_amount, [Validators.pattern(CURRENCY)]));
  form.addControl('judgement_date', new FormControl<string>(task?.judgement_date ?? task?.due_date, requireJudgement ? [Validators.required] : []));
  form.addControl('completed_date', new FormControl<string>(task?.completed_date));
  form.addControl('comment', new FormControl<string>(''));

  if (task) {
    form.patchValue(task);
    form.get('judgement_date')?.setValue(task.judgement_date ?? task.due_date);
  }

  return form;
}

export function generateWorkflowTaskForm(fb: FormBuilder, task: WorkflowStepTask | null = null, nameOverride: string | null = null, stepNumber: number | null = null): FormGroup {
  const form = generateBaseTaskForm(fb, TaskType.WORKFLOW_STEP, task?.name ?? nameOverride ?? 'Workflow Step');

  form.addControl('step_number', new FormControl<number>(task?.step_number ?? stepNumber, [Validators.required]));

  if (task) {
    form.patchValue(task);
  }
  return form;
}
