import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import {ClientService} from '@ee/common/services';
import {ValidateClientIdentifierNotTaken} from '@ee/common/validators';
import {IntakeEvictionType} from '@ee/common/enums';
import {FileValidator} from 'ngx-custom-material-file-input';
import {MAX_FILE_UPLOAD_SIZE} from '@ee/common/constants';
import {StepCompletionModel} from '@ee/common/models';

export type IntakeEvictionForm = FormGroup<{
  type: FormControl<IntakeEvictionType>;
  selected_workflow_id: FormControl<string | null>;
  selected_county_id: FormControl<string | null>;
  selected_attorney_user_id: FormControl<string | null>;
  auto_select_next: FormControl<boolean>;
  selected_client_id: FormControl<string | null>;
  step_completion_details: FormControl<StepCompletionModel | null>;
}>;

export function GenerateProcessIntakeEvictionForm(fb: FormBuilder, type: IntakeEvictionType): IntakeEvictionForm {
  return fb.group({
    type: new FormControl<IntakeEvictionType>(type, Validators.required),
    selected_workflow_id: new FormControl(null, Validators.required),
    selected_county_id: new FormControl(null),
    selected_attorney_user_id: new FormControl(null),
    auto_select_next: new FormControl(true),
    step_completion_details: new FormControl(null),
    selected_client_id: new FormControl(null)
  });
}

export function GenerateNewClientIdentifierControl(value: string, clientService: ClientService) {
  const control =  new UntypedFormControl(value, {
    validators: [Validators.required, Validators.minLength(2),
      Validators.maxLength(10), Validators.pattern('[a-zA-Z0-9]*')] as any[],
    asyncValidators: [ValidateClientIdentifierNotTaken.createValidator(clientService)],
    updateOn: 'change'
  } as any);
  control.updateValueAndValidity({onlySelf: true, emitEvent: true});
  control.markAsTouched({onlySelf: true});
  return control;
}


export function GenerateIntakeEvictionForm(fb: UntypedFormBuilder, intakeEvictionType: IntakeEvictionType, requireClientDetails: boolean) {
  // add validator if option specified
  const clientDetailsValidators = [];
  if (requireClientDetails) {
    clientDetailsValidators.push(Validators.required);
  }

  return fb.group({
    type: intakeEvictionType,
    attorney_id: [undefined, Validators.required],
    selected_workflow_id: undefined,
    document_templates: [],
    hidden_document_templates: [],
    user_details: undefined,
    step_completion_details: undefined,
    client_details: [undefined, clientDetailsValidators],
    eviction_details: [undefined, Validators.required],
    lease_agreement: [undefined, [Validators.required, FileValidator.maxContentSize(MAX_FILE_UPLOAD_SIZE)]],
    ledger: [undefined, [Validators.required, FileValidator.maxContentSize(MAX_FILE_UPLOAD_SIZE)]],
    note: undefined
  });
}
