import { Component, Input } from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import { TaskType } from '@ee/common/enums';

@Component({
  selector: 'ee-task-form',
  template: `
    <div [formGroup]="taskForm" class="flex flex-col justify-start items-stretch">
      <mat-form-field class="task-name compact mb-4">
        <mat-label>Task Name</mat-label>
        <input matInput formControlName="name" />
        <mat-hint>
          {{!taskForm.get('name').hasValidator(Validators.required) ? 'Optional.' : 'Required.'}}
        </mat-hint>
        <mat-error *ngIf="taskForm.get('name').hasError('required')">
          Task Name is required
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="!hideDescription" class="task-description compact mb-4">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" cols="5"></textarea>
        <mat-hint>Optional</mat-hint>
      </mat-form-field>
      <ee-user-selector *ngIf="taskForm.get('type').value !== TaskType.COURT" class="mb-4"
                        [selectedUsers]="$any(taskForm.get('assigned_users'))"></ee-user-selector>
      <ee-task-schedule class="mb-4" [taskType]="taskForm.get('type').value"
                        [dueDateControl]="$any(taskForm.get('due_date'))"
                        [showTimeControl]="$any(taskForm.get('show_time'))"></ee-task-schedule>

<!--      <mat-form-field class="task-priority>-->
<!--        <mat-label>Priority</mat-label>-->
<!--        <mat-select formControlName="priority">-->
<!--          <mat-option *ngFor="let priority of [0, 1, 2, 3, 4, 5]" [value]="priority">-->
<!--            {{priority}}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
<!--      <mat-form-field class="task-type">-->
<!--        <mat-label>Type</mat-label>-->
<!--        <mat-select formControlName="type">-->
<!--          <mat-option *ngFor="let type of [0, 1, 2]" [value]="type">-->
<!--            {{type}}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
<!--      <mat-form-field class="task-tags">-->
<!--        <mat-label>Tags</mat-label>-->
<!--        <tag-input class="bg-white" formControlName="tags" placeholder="+ Tags" [disable]="false" [removable]="true"-->
<!--                   [allowDupes]="false" [clearOnBlur]="true" [modelAsStrings]="true" [hideForm]="false"-->
<!--                   [editable]="false"></tag-input>-->
<!--      </mat-form-field>-->
    </div>
  `
})
export class TaskFormComponent {
  taskTypes = TaskType;
  @Input({required: true}) taskForm: FormGroup;
  @Input({required: false}) hideDescription = false;
  protected readonly Validators = Validators;
  protected readonly TaskType = TaskType;
}
