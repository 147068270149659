import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {SearchSuggestionModel} from '@ee/common/models';
import {Observable, of} from 'rxjs';


@Injectable({providedIn: 'root'})
export class SearchService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  suggestions(searchKeyword: string, types: string[] = []): Observable<SearchSuggestionModel[]> {
    if (searchKeyword && searchKeyword.length > 1) {
      const params = {keyword: searchKeyword};
      if (types?.length) {
        params['types'] = types;
      }

      return this.http.get<SearchSuggestionModel[]>(`${this.environment.api_prefix}api/search/suggestions`, {params});
    } else {
      return of([]);
    }
  }

  searchAddress(searchKeyword: string): Observable<any> {
    if (searchKeyword && searchKeyword.length > 2) {
      return this.http.get<any>(
        `${this.environment.api_prefix}api/search/address?keyword=${encodeURIComponent(searchKeyword)}`);
    }
    return of([]);
  }
}
