import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TaskType } from '@ee/common/enums';

@Component({
  selector: 'ee-task-schedule',
  template: `
    <div class="flex flex-row items-center">
      <mat-form-field class="compact flex-1">
        <mat-label>{{taskType === taskTypes.COURT ? 'Court' : 'Due'}} Date</mat-label>
        <mtx-datetimepicker #datetimePicker
                            [type]="taskType === taskTypes.COURT && showTimeControl.value ? 'datetime' : 'date'"
                            mode="landscape"
                            multiYearSelector="false"
                            startView="month"
                            twelvehour="true"
                            touchUi="false"
                            startAt="Date.now()"
                            [timeInterval]="5"
                            [timeInput]="taskType === taskTypes.COURT"></mtx-datetimepicker>
        <input [mtxDatetimepicker]="datetimePicker" [formControl]="$any(dueDateControl)" matInput>
        <mtx-datetimepicker-toggle [for]="datetimePicker" matSuffix></mtx-datetimepicker-toggle>
        <mat-hint>
          {{required ? 'Required.' : 'Optional.'}}
        </mat-hint>
        <mat-error *ngIf="taskType === taskTypes.COURT && dueDateControl.hasError('sameValues')">
          Court date and time cannot be the same as the current value.
        </mat-error>
      </mat-form-field>
      <mat-slide-toggle *ngIf="taskType === taskTypes.COURT && showTimeControl"
                        class="ml-4" [formControl]="$any(showTimeControl)">Show Time
      </mat-slide-toggle>
    </div>
  `
})
export class TaskScheduleComponent {
  public readonly taskTypes = TaskType;

  @Input({ required: true })
  public taskType: TaskType;

  @Input({ required: true })
  public dueDateControl: FormControl;

  @Input({ required: false })
  public showTimeControl: FormControl | null = null;

  @Input() public required = false;
}
