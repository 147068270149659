export enum UserRoles {
  ATTORNEY = 'Attorney',
  PARALEGAL = 'Paralegal',
  BOOKKEEPER = 'Bookkeeper',
  PROCESS_SERVER = 'Process Server',
  RECEPTIONIST = 'Receptionist',
  CORPORATE_ADMIN = 'Corporate Administrator',
  ON_SITE_ADMIN = 'On-Site Administrator',
  REGIONAL_MANAGER = 'Regional Manager'
}
