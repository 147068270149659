import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {User, RegistrationModel, Account, UserInvite} from '@ee/common/models';
import {UserRoles} from '@ee/common/enums';

@Injectable({providedIn: 'root'})
export class UserService {
  public resourceUrl: string;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
    this.resourceUrl = this.environment.api_prefix + 'api/users';
  }

  getLoggedInUser(forceRefresh = true): Observable<User> {
    let queryParams = '?include-dashboard=true';

    if (forceRefresh) {
      queryParams += '&force-refresh=true';
    }
    return this.http.get<User>(`${this.environment.api_prefix}api/users/current${queryParams}`);
  }

  register(registrationModel: RegistrationModel): Observable<HttpResponse<any>> {
    return this.http.post(`${this.environment.api_prefix}api/register`, registrationModel, {observe: 'response'});
  }

  getUserSuggestions(attorneyUsers: boolean = false): Observable<User[]> {
    const params = {};
    if (attorneyUsers) {
      params['attorney-users'] = true;
    }
    return this.http.get<User[]>(`${this.environment.api_prefix}api/users/suggestions`, {params});
  }

  getUsersByOrganization(orgId: string, simple = false): Observable<User[]> {
    const params = {};
    if (simple) {
      params['simple'] = true;
    }
    return this.http.get<User[]>(`${this.environment.api_prefix}api/organizations/${orgId}/users`, { params });
  }

  checkEmailAvailability(emailAddress: string, limitToCurrentOrganization = false, clientId: string = null):
    Observable<boolean> {
    let queryParams = `?login=${encodeURIComponent(emailAddress)}`;
    if (clientId) {
      queryParams += `&client-id=${clientId}`;
    }
    return this.http.get<boolean>(
      `${this.resourceUrl}/check-availability/limit/${limitToCurrentOrganization}` + queryParams);
  }

  confirmAgreements(): Observable<User> {
    return this.http.put<User>(`${this.environment.api_prefix}api/users/confirm-agreements`, {});
  }

  accounts(): Observable<Account[]> {
    return this.http.get<Account[]>(`${this.environment.api_prefix}api/users/accounts`);
  }

  switchAccount(accountId: string): Observable<void> {
    return this.http.post<void>(`${this.environment.api_prefix}api/users/accounts/${accountId}/switch`, {});
  }

  acceptAccountInvite(accountId: string): Observable<Account[]> {
    return this.http.post<Account[]>(`${this.environment.api_prefix}api/users/accounts/${accountId}/accept`, {});
  }

  rejectAccountInvite(accountId: string): Observable<null> {
    return this.http.delete<null>(`${this.environment.api_prefix}api/users/accounts/${accountId}`);
  }

  getUsers(clientId: string = null, roles: UserRoles[] = [], simple = false): Observable<User[]> {
    const params = {};
    if (clientId) {
      params['client-id'] = clientId;
    }
    if (roles?.length) {
      params['roles'] = roles.join(',');
    }
    if (simple) {
      params['simple'] = true;
    }
    return this.http.get<User[]>(this.resourceUrl, { params });
  }

  addUser(userInvite: UserInvite, clientId?: string): Observable<User> {
    let clientQuery = '';
    if (clientId) {
      clientQuery += `?client-id=${clientId}`;
    }
    return this.http.post<User>(this.resourceUrl + clientQuery, userInvite);
  }

  updateUser(user: User, clientId?: string): Observable<User> {
    let clientQuery = '';
    if (clientId) {
      clientQuery += `?client-id=${clientId}`;
    }
    return this.http.put<User>(this.resourceUrl + clientQuery, user);
  }

  deleteUser(login: string, clientId?: string): Observable<void> {
    let clientQuery = '';
    if (clientId) {
      clientQuery += `?client-id=${clientId}`;
    }
    return this.http.delete<void>(`${this.resourceUrl}/${login}${clientQuery}`);
  }

  toggleDisableUser(login: string, clientId?: string): Observable<boolean> {
    let clientQuery = '';
    if (clientId) {
      clientQuery += `?client-id=${clientId}`;
    }
    return this.http.put<boolean>(`${this.resourceUrl}/${login}/toggle-disabled${clientQuery}`, {});
  }

  updateUserPositionGroup(login: string, permissionGroupId: string, clientId?: string): Observable<void> {
    return this.http.put<void>(`${this.resourceUrl}/${login}/permission-group`, { permission_group_id: permissionGroupId, client_id: clientId });
  }

  updateUserPosition(login: string, role: string, clientId?: string): Observable<void> {
    return this.http.put<void>(`${this.resourceUrl}/${login}/switch-role`, { role, client_id: clientId });
  }

  resetUserPassword(login: string): Observable<void> {
    return this.http.put<void>(`${this.resourceUrl}/${login}/reset-password`, {});
  }

  resendInvite(login: string, clientId?: string): Observable<void> {
    let clientQuery = '';
    if (clientId) {
      clientQuery += `?client-id=${clientId}`;
    }
    return this.http.put<void>(`${this.resourceUrl}/${login}/resend-invite${clientQuery}`, {});
  }
}
