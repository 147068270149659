import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { CustomField } from '@ee/common/models';

@Component({
  selector: 'ee-uploader',
  template: `
    <div class="ee__standard-shadow m-4 p-4">
      <div class="mb-2">
        <div class="flex flex-row justify-between items-center">
          <h3 class="no-wrap">{{fields.label}}</h3>
          <div class="errors">
            <b
              *ngIf="form?.touched && form?.hasError('required'); else sizeLabel">
              Document must be selected to continue.
            </b>
            <ng-template #sizeLabel>
              <b *ngIf="form?.hasError('maxContentSize')">
                The total size must not
                exceed {{form?.getError('maxContentSize').maxSize | byteFormat}}
                (selected file is {{form?.getError('maxContentSize').actualSize
                | byteFormat}}).</b>
            </ng-template>
          </div>
        </div>

        <div class="flex flex-row justify-between items-start">
          <div><b *ngIf="fields.required">Required. </b><span *ngIf="!fields.link_id">{{fields.hint}}.</span></div>
          <div class="no-wrap cursor-pointer" (click)="clearSelectedFile(form)">
            <u>Clear Selection</u>
          </div>
        </div>
      </div>
      <div class="card-content flex flex-row justify-center items-center">
        <div *ngIf="fields.link_id" class="flex flex-col flex-1 h-full w-full justify-start items-stretch">
          <div>1. Click here to download the form:</div>
          <button type="button" mat-flat-button color="primary" (click)="downloadForm(fields.link_id)">
            Download Form
          </button>
          <div>2. Print and fill out the form</div>
          <div>3. Upload form here</div>
        </div>
        <ee-upload-selector class="ml-2 flex-1"
                     [formControl]="$any(form)" [required]="fields.required"
                     [hasError]="$any(form).touched && form?.invalid">
        </ee-upload-selector>
      </div>
    </div>
  `,
  styles: [
    `
      @import 'components/color-palette';
      @import 'components/utils';

      h3 {
        font-size: 1rem;
      }

      .mat-mdc-form-field {
        margin-bottom: 1rem;
      }

      .no-wrap {
        white-space: nowrap;
      }

      .errors {
        color: $primary-red;
        text-align: right;
      }

      .upload-wrapper {
        margin-bottom: 1rem;

        .label {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 1.5rem;
          padding-bottom: 0.5rem;
          text-align: center;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploaderComponent {
  @Input() fields: CustomField;
  @Input() form: UntypedFormControl;
  @Output() downloadFormEvent = new EventEmitter<string>();

  public visibility: boolean[] = [];
  public required: boolean[] = [];

  public downloadForm(formLink: string): void {
    this.downloadFormEvent.emit(formLink);
  }

  public clearSelectedFile(control: AbstractControl) {
    control.setValue(undefined);
  }
}
