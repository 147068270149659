import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompleteWorkflowStepComponent} from './complete-workflow-step.component';
import {MatInputModule} from '@angular/material/input';
import {CustomFieldModule} from '@ee/common/custom-field';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {BillableItemEditorModule} from '@ee/common/billable-item-editor';
import {DocumentTemplateEditorModule} from '@ee/common/document-template-editor';
import {TasksModule} from '@ee/common/tasks';
import {LuxonModule} from 'luxon-angular';


@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    CustomFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    BillableItemEditorModule,
    DocumentTemplateEditorModule,
    TasksModule,
    LuxonModule,

  ],
  declarations: [CompleteWorkflowStepComponent],
  exports: [CompleteWorkflowStepComponent]
})
export class WorkflowStepModule {}
