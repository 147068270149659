import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {UntypedFormBuilder} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  GenerateIntakeEvictionForm
} from '@ee/common/forms';
import {
  CaseSettings,
  CustomField,
  IntakeEviction,
  Organization,
  User,
  Workflow
} from '@ee/common/models';
import {
  IntakeEvictionService
} from '@ee/common/services';
import {AccountType, IntakeEvictionType, SubscriptionPlan} from '@ee/common/enums';
import {MatStepper} from '@angular/material/stepper';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {EVICTION_REASONS_CLAIMS_MAP} from '@ee/common/constants';
import { IntakeEvictionFormService } from '../services/intake-eviction-form.service';

@Component({
  selector: 'ee-create-eviction',
  templateUrl: './create-eviction.component.html',
  styleUrls: ['./create-eviction.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class CreateEvictionComponent implements OnInit, OnChanges, OnDestroy {
  subs: Subscription[] = [];
  attorneyAccount = AccountType.ATTORNEY;
  saving = false;
  currentStep = 0;
  public selectedWorkflow: Workflow | undefined = undefined;

  @Input()
    attorneyId: string;

  @Input()
    forceIntakeForm = false;

  @Input()
    firstStepLabel = 'Client & Workflow';

  @Input()
    secondStepLabel = 'Claims';

  @Input()
    lastStepLabel = 'Documentation';

  @Input()
    loggedInUser: User | undefined;

  @Input()
    loggedInOrg: Organization | undefined;

  @Input()
    currentPlan: SubscriptionPlan | undefined;

  @Input()
    workflows: Workflow[];

  @Input()
    selectedClientId?: string;

  @Input() caseSettings: CaseSettings;

  @Output()
    addClient: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
    evictionCreated: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();

  protected readonly EVICTION_REASONS_CLAIMS_MAP = EVICTION_REASONS_CLAIMS_MAP;


  public smallScreen = false;

  constructor(private fb: UntypedFormBuilder,
              private intakeEvictionService: IntakeEvictionService,
              private snackBar: MatSnackBar,
              private breakpointObserver: BreakpointObserver,
              private changeDetectorRef: ChangeDetectorRef,
              public intakeEvictionFormService: IntakeEvictionFormService) {
  }

  async ngOnInit() {
    // calculate screen size
    this.subs.push(this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
      .subscribe((state: BreakpointState) => {
        this.smallScreen = state.matches;
      }));

    const accountType: AccountType | undefined = this.loggedInOrg?.type;
    const showLoggedInForm = this.loggedInUser && this.loggedInOrg && this.currentPlan && !this.forceIntakeForm;
    let attorneyId = this.attorneyId;
    if (!attorneyId) {
      attorneyId = this.attorneyAccount === accountType ? this.loggedInOrg.id : this.loggedInOrg.attorney?.id;
    }
    if (showLoggedInForm) {
      if (accountType === AccountType.ATTORNEY) {
        attorneyId = this.loggedInOrg.id;
      } else {
        attorneyId = this.loggedInOrg.attorney?.id;
        this.firstStepLabel = 'Attorney';
        this.secondStepLabel = 'Claims';
        this.lastStepLabel = 'Documentation';
      }
    }

    await this.intakeEvictionFormService.initialize(this.forceIntakeForm, attorneyId, this.loggedInUser, this.loggedInOrg);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workflows'] && this.workflows?.length) {
      this.intakeEvictionFormService.updateWorkflow(this.workflows);
    }
  }

  /**
   * Prevent dropping files into non-dropzone areas (and having browser open file in place of page)
   *
   * @param event
   */
  @HostListener('document:dragover', ['$event'])
  @HostListener('drop', ['$event'])
  onDragDropFileVerifyZone(event) {
    if (event.path?.filter(x => x?.nodeName === 'EE-UPLOAD')?.length) {
      // In drop zone. I don't want listeners later in event-chain to meddle in here
      event.stopPropagation();
    } else {
      // Outside of drop zone! Prevent default action, and do not show copy/move icon
      event.preventDefault();
      event.dataTransfer.effectAllowed = 'none';
      event.dataTransfer.dropEffect = 'none';
    }
  }

  async goForward(currentStep: number, stepper: MatStepper) {
    if (currentStep === 1) {
      if (this.loggedInOrg?.type === this.attorneyAccount && this.intakeEvictionFormService.workflowDetails.invalid) {
        this.intakeEvictionFormService.workflowDetails.markAllAsTouched();
        return;
      }
      await this.intakeEvictionFormService.setupForms();
    } else if (currentStep === 3 && (this.intakeEvictionFormService.propertyDetails.invalid || this.intakeEvictionFormService.tenantDetails.invalid)) {
      this.intakeEvictionFormService.tenantDetails.markAllAsTouched();
      this.intakeEvictionFormService.propertyDetails.markAllAsTouched();
      return;
    } else if (currentStep === 4 && (this.intakeEvictionFormService.claimDetails.invalid || this.intakeEvictionFormService.leaseTermsDetails.invalid)) {
      this.intakeEvictionFormService.claimDetails.markAllAsTouched();
      this.intakeEvictionFormService.leaseTermsDetails.markAllAsTouched();
      return;
    }
    this.currentStep = currentStep;
    this.intakeEvictionFormService.generateEvictionDetails();
    this.changeDetectorRef.detectChanges();
    stepper.next();
  }

  goBack(currentStep: number, stepper: MatStepper) {
    if (currentStep === 2) {
      this.intakeEvictionFormService.removeForms();
    }
    this.currentStep = currentStep - 1;
    stepper.previous();
  }

  stepChanged() {
    // scroll to top
    window.document.querySelector('.mat-horizontal-content-container')?.scrollTo(0, 0);
  }

  save() {
    if (this.intakeEvictionFormService.splitForm.invalid) {
      this.intakeEvictionFormService.documents.markAllAsTouched();
      return;
    }

    this.saving = true;

    const workflowDetails = this.intakeEvictionFormService.workflowDetails.value;
    const documents = this.intakeEvictionFormService.documents.value;
    const claimDetails = this.intakeEvictionFormService.claimDetails.value;

    // add file and input values into form
    const formData = new FormData();
    this.intakeEvictionFormService.documentSection.fields.forEach(((documentFields: CustomField, index: number) => {
      if (documents[index] && documents[index].length) {
        const file = documents[index][0];
        formData.append(documentFields.field, file, file.name);
      }
    }));

    // merge split data into intake form
    const form = GenerateIntakeEvictionForm(this.fb,
      this.attorneyId ? IntakeEvictionType.INTAKE_FORM : IntakeEvictionType.EXISTING_CLIENT, true);
    const intakeEviction: IntakeEviction = form.value;
    intakeEviction.attorney_id = workflowDetails.attorney_id;
    if (!this.loggedInOrg || this.loggedInOrg.type !== AccountType.CLIENT) {
      intakeEviction.client_details = workflowDetails.client_details;
      intakeEviction.user_details = workflowDetails.user_details;
    }
    intakeEviction.intake_form_id = this.intakeEvictionFormService.intakeForm?.id;
    intakeEviction.selected_workflow_id = workflowDetails.workflow_id;
    intakeEviction.selected_attorney_user_id = workflowDetails.selected_attorney_user_id;
    intakeEviction.note = this.intakeEvictionFormService.splitForm.value.note;
    intakeEviction.captcha = this.intakeEvictionFormService.splitForm.value.captcha;
    intakeEviction.step_completion_details = this.intakeEvictionFormService.splitForm.value.step_completion_details;


    intakeEviction.partial_payments = claimDetails.partial_payments; // populate partial payments list
    intakeEviction.eviction_details = this.intakeEvictionFormService.generateEvictionDetails();

    // generate form data
    formData.append('data', JSON.stringify(intakeEviction));

    this.subs.push(this.intakeEvictionService.saveIntakeEviction(intakeEviction.type, formData).subscribe(
      {
        next: (caseId: string | undefined) => {
          this.evictionCreated.emit(caseId);
        },
        error: () => {
          this.snackBar.open('An error occurred submitting your request. Please try again shortly.',
            'Dismiss', {duration: 3000});
          this.saving = false;
        },
        complete: () => {
          this.saving = false;
        }
      }
    ));
  }

  ngOnDestroy(): void {
    this.intakeEvictionFormService.clearForm();
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
