import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MtxLuxonDatetimeModule } from '@ng-matero/extensions-luxon-adapter';
import { CaseTaskSummaryComponent } from './case-task-summary.component';
import { LuxonModule } from 'luxon-angular';
import { MtxPopoverModule } from '@ng-matero/extensions/popover';
import { TaskFormComponent } from './task-form.component';
import { TagInputModule } from 'ngx-chips';
import { ActiveTasksFilterPipe } from './active-tasks-filter.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IpeModule } from '@ee/common/ipe';
import { CourtJudgementComponent } from './court-judgement.component';
import { TaskScheduleComponent } from './task-schedule.component';
import { UserSelectorModule } from '@ee/common/user-selector';
import { EePipesModule } from '@ee/common/pipes';
import { CourtTaskSummaryComponent } from './court-task-summary.component';
import { CourtTaskInfoComponent } from './court-task-info.component';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatDatepickerModule,
    MtxDatetimepickerModule,
    MatOptionModule,
    MatSelectModule,
    MtxLuxonDatetimeModule,
    LuxonModule,
    MtxPopoverModule,
    TagInputModule,
    MatTooltipModule,
    MatSlideToggleModule,
    IpeModule,
    UserSelectorModule,
    EePipesModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay
  ],
  declarations: [
    TaskFormComponent,
    TaskScheduleComponent,
    CaseTaskSummaryComponent,
    CourtJudgementComponent,
    ActiveTasksFilterPipe,
    CourtTaskSummaryComponent,
    CourtTaskInfoComponent
  ],
  exports: [
    TaskFormComponent,
    TaskScheduleComponent,
    CaseTaskSummaryComponent,
    CourtJudgementComponent,
    ActiveTasksFilterPipe,
    CourtTaskSummaryComponent,
    CourtTaskInfoComponent
  ]
})
export class TasksModule {}
