import {ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {BillableItem, ConfirmDialogModel, GenerateBillableItemForm, WorkflowStep} from '@ee/common/models';
import {BillableItemEditorDialogComponent} from './billable-item-editor-dialog.component';
import {ConfirmDialogComponent} from '@ee/common/confirm-dialog';
import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ee-workflow-step-billables',
  template: `
    <div class="details mat-chips status flex-wrap flex flex-row">
      <div class="mat-chip green whitespace-nowrap mb-4 mr-4" *ngFor="let b of billableItemsArray.controls; let i = index">
        {{b.get('amount').value | currency}} {{b.get('description').value}}
        <i class="fa fa-xmark ml-2 cursor-pointer" (click)="removeBillableItem(i)"
           *ngIf="workflowStep?.allow_billable_override || b.get('amount').value === 0"></i>
      </div>
      <span *ngIf="workflowStep?.allow_billable_override || overrideAddBillable" (click)="addBillable()"
            class="font-medium cursor-pointer select-none whitespace-nowrap ml-2">
          + Add Billable Item
        </span>
    </div>
  `,
  styles: [`
    .mat-chips.details {
      padding-bottom: 0;

      .mat-chip {
        margin-bottom: 0.5rem;
      }
    }
  `]
})
export class WorkflowStepBillablesComponent implements OnDestroy {
  @Input() workflowStep: WorkflowStep;
  @Input() billableItemsArray: UntypedFormArray;
  @Input() overrideAddBillable = false;

  private subs: Subscription[] = [];

  constructor(public dialog: MatDialog, private fb: UntypedFormBuilder,
              public changeDetectorRef: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  addBillable() {
    const dialogRef = this.dialog.open(BillableItemEditorDialogComponent, {
      width: '500px',
      data: { title: 'Add Billable Item' }
    });
    this.subs.push(
      dialogRef.afterClosed().subscribe((billableItem: BillableItem) => {
        if (billableItem) {
          this.billableItemsArray.push(GenerateBillableItemForm(this.fb, billableItem));
        }
      })
    );
  }

  removeBillableItem(i: number) {
    const billable = this.billableItemsArray.controls[i];
    const confirmDialog = {
      title: 'Are you sure?',
      description:
        `The billable item for <b>${billable.get('description').value}</b> in the amount ` +
        `of <b>$${billable.get('amount').value}</b> will be removed.`
    } as ConfirmDialogModel;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: confirmDialog });
    this.subs.push(
      dialogRef.afterClosed().subscribe((decision: boolean) => {
        if (decision) {
          this.billableItemsArray.removeAt(i);
          this.changeDetectorRef.detectChanges();
        }
      })
    );
  }
}
