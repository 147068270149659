import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Eviction, IntakeFormSection, IntakeOption} from '@ee/common/models';
import Big from 'big.js';
import {IntakeFormFields, RuleTargetAction, RuleTargetType} from '@ee/common/enums';
import {ConditionEvaluatorService} from '@ee/common/services';

@Component({
  selector: 'ee-claims-section',
  template: `
    <div class="section-wrapper" [formGroup]="form" *ngIf="!hideSection; else noClaims">
      <div class="flex flex-col justify-start items-stretch" *ngIf="options[intakeFields.CLAIMS]?.type === 'simple'; else advanced">
        <div class="flex flex-row justify-center items-center mb-4">
          <mat-form-field>
            <mat-label>Total Due</mat-label>
            <span matPrefix>$ &nbsp;</span>
            <input matInput type="number" formControlName="tenant_total_owed"
                   required
                   (click)="$any($event.target)?.select()" autocomplete="off">
            <mat-error *ngIf="form?.get('tenant_total_owed').errors">
              Total must be greater than 0.
            </mat-error>
            <mat-hint>Required</mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="claimsSection?.fields?.length && claimsFields" class="flex flex-row flex-wrap">
          <ee-field class="mr-4 mb-4" *ngFor="let f of claimsSection?.fields; let i = index"
                    [ngClass]="{'margin-bottom-sm': i < (claimsSection.fields.length - 1),
                                'md:mb-4': i < (claimsSection.fields.length - 1),
                                'double-column': f.flex === 2, 'triple-column': f.flex === 3, 'quad-column': f.flex === 4}"
                    [hideFromIntake]="f.hide_from_intake"
                    [hidden]="f.hide_from_intake && !overrideFieldVisibility"
                    [control]="$any(claimsFields.controls[f.field])" [field]="f">
          </ee-field>
        </div>
      </div>
      <ng-template #advanced>
        <ee-past-due-rent-history
          *ngIf="options[intakeFields.PAST_DUE_RENT_HISTORY]?.show"
          [form]="form" (valueChanged)="updateTotal($event)"></ee-past-due-rent-history>
        <div class="in-row-grid">
          <mat-form-field class="flex-1 md:flex-none" *ngIf="(options[intakeFields.PAST_DUE_RENT].show || overrideFieldVisibility) &&
                                                !(options[intakeFields.PAST_DUE_RENT_HISTORY]?.show)"
                          [ngClass]="{'hidden-from-intake': !!options[intakeFields.PAST_DUE_RENT]?.hide_from_intake}">
            <mat-label>Rent Owed</mat-label>
            <span matPrefix>$ &nbsp;</span>
            <input matInput type="number" formControlName="past_due_rent"
                   [required]="options[intakeFields.PAST_DUE_RENT].required">
            <mat-error *ngIf="form?.get('past_due_rent').hasError('required')">
              Please enter a value.
            </mat-error>
            <mat-error *ngIf="form?.get('past_due_rent').hasError('pattern')">
              Invalid currency format.
            </mat-error>
            <mat-hint class="whitespace-nowrap">
              {{ options[intakeFields.PAST_DUE_RENT].required ? 'Required' : 'Optional' }}
              <span class="field-instructions-wrapper" *ngIf="options[intakeFields.PAST_DUE_RENT].hint">
                  . Hover for <span class="field-instructions" [tooltip]="options[intakeFields.PAST_DUE_RENT].hint"
                                    placement="bottom">
                  more info
                </span>.
                </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="flex-1 md:flex-none" *ngIf="showField(options[intakeFields.LATE_FEES])"
                          [ngClass]="{'hidden-from-intake': !!options[intakeFields.LATE_FEES]?.hide_from_intake}">
            <mat-label>Late Fee</mat-label>
            <span matPrefix>$ &nbsp;</span>
            <input matInput type="number" formControlName="late_fees"
                   [required]="options[intakeFields.LATE_FEES].required">
            <mat-error *ngIf="form?.get('late_fees').hasError('required')">
              Please enter a value.
            </mat-error>
            <mat-error *ngIf="form?.get('late_fees').hasError('pattern')">
              Invalid currency format.
            </mat-error>
            <mat-hint class="whitespace-nowrap">
              {{ options[intakeFields.LATE_FEES].required ? 'Required' : 'Optional' }}
              <span class="field-instructions-wrapper" *ngIf="options[intakeFields.LATE_FEES].hint">
                  . Hover for <span class="field-instructions" [tooltip]="options[intakeFields.LATE_FEES].hint"
                                    placement="bottom">
                  more info
                </span>.
                </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="double-column" *ngIf="showField(options[intakeFields.DELINQUENCY_LENGTH])"
                          [ngClass]="{'hidden-from-intake': !!options[intakeFields.DELINQUENCY_LENGTH]?.hide_from_intake}">
            <mat-label>Delinquency Length</mat-label>
            <input matInput type="text" formControlName="delinquency_length"
                   [required]="options[intakeFields.DELINQUENCY_LENGTH].required">
            <mat-error *ngIf="form?.get('delinquency_length').hasError('required')">
              Please enter a value.
            </mat-error>
            <mat-hint class="whitespace-nowrap">
              {{ options[intakeFields.DELINQUENCY_LENGTH].required ? 'Required' : 'Optional' }}.&nbsp;
              Ex. Jan 1, {{year}} - Mar 1, {{year}}{{options[intakeFields.DELINQUENCY_LENGTH].hint ? '' : '.'}}
              <span class="field-instructions-wrapper" *ngIf="options[intakeFields.DELINQUENCY_LENGTH].hint">
                  . Hover for
                  <span class="field-instructions" [tooltip]="options[intakeFields.DELINQUENCY_LENGTH].hint"
                        placement="bottom">
                    more info
                  </span>.
                </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="in-row-grid"
             *ngIf="showingAtLeastOne(intakeFields.DAMAGES, intakeFields.DAMAGES_DESCRIPTION)">
          <mat-form-field class="flex-1 md:flex-none" *ngIf="showField(options[intakeFields.DAMAGES])"
                          [ngClass]="{'hidden-from-intake': !!options[intakeFields.DAMAGES]?.hide_from_intake}">
            <mat-label>Damages</mat-label>
            <span matPrefix>$ &nbsp;</span>
            <input matInput type="number" formControlName="damages"
                   [required]="options[intakeFields.DAMAGES].required">
            <mat-error *ngIf="form.get('damages').hasError('required')">
              Please enter a value.
            </mat-error>
            <mat-error *ngIf="form.get('damages').hasError('pattern')">
              Invalid currency format.
            </mat-error>
            <mat-hint class="whitespace-nowrap">
              {{ options[intakeFields.DAMAGES].required ? 'Required' : 'Optional' }}
              <span class="field-instructions-wrapper" *ngIf="options[intakeFields.DAMAGES].hint">
                  . Hover for
                  <span class="field-instructions" [tooltip]="options[intakeFields.DAMAGES].hint" placement="bottom">
                    more info
                  </span>.
                </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="triple-column" *ngIf="showField(options[intakeFields.DAMAGES_DESCRIPTION])"
                          [ngClass]="{'hidden-from-intake': !!options[intakeFields.DAMAGES_DESCRIPTION]?.hide_from_intake}">
            <mat-label>Damage Description</mat-label>
            <input matInput type="text"
                   formControlName="damages_description"
                   [required]="options[intakeFields.DAMAGES_DESCRIPTION].required">
            <mat-error *ngIf="form?.get('damages_description').hasError('required')">
              Please enter a description.
            </mat-error>
            <mat-hint class="whitespace-nowrap">
              {{ options[intakeFields.DAMAGES_DESCRIPTION].required ? 'Required' : 'Optional' }}
              <span class="field-instructions-wrapper" *ngIf="options[intakeFields.DAMAGES_DESCRIPTION].hint">
                  . Hover for
                  <span class="field-instructions" [tooltip]="options[intakeFields.DAMAGES_DESCRIPTION].hint"
                        placement="bottom">
                    more info
                  </span>.
                </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="in-row-grid"
             *ngIf="showingAtLeastOne(intakeFields.INTEREST_RATE, intakeFields.INTEREST_START_DATE)">
          <mat-form-field *ngIf="showField(options[intakeFields.INTEREST_RATE])"
                          [ngClass]="{'hidden-from-intake': !!options[intakeFields.INTEREST_RATE]?.hide_from_intake}">
            <mat-label>Interest Rate</mat-label>
            <input matInput type="number" formControlName="interest_rate"
                   [required]="options[intakeFields.INTEREST_RATE].required">
            <span matSuffix>%</span>
            <mat-error *ngIf="form?.get('interest_rate').hasError('required')">
              Please enter a value.
            </mat-error>
            <mat-hint class="whitespace-nowrap">
              {{ options[intakeFields.INTEREST_RATE].required ? 'Required' : 'Optional' }}
              <span class="field-instructions-wrapper" *ngIf="options[intakeFields.INTEREST_RATE].hint">
                  . Hover for
                  <span class="field-instructions" [tooltip]="options[intakeFields.INTEREST_RATE].hint"
                        placement="bottom">
                    more info
                  </span>.
                </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field *ngIf="showField(options[intakeFields.INTEREST_START_DATE])"
                          [ngClass]="{'hidden-from-intake': !!options[intakeFields.INTEREST_START_DATE]?.hide_from_intake}">
            <mat-label>Interest Start Date</mat-label>
            <input matInput [matDatepicker]="dpInterest" formControlName="interest_start_date"
                   [required]="options[intakeFields.INTEREST_START_DATE].required">
            <mat-error *ngIf="form?.get('interest_start_date').hasError('required')">
              Please enter a value.
            </mat-error>
            <mat-hint class="whitespace-nowrap">
              {{ options[intakeFields.INTEREST_START_DATE].required ? 'Required' : 'Optional' }}
              <span class="field-instructions-wrapper" *ngIf="options[intakeFields.INTEREST_START_DATE].hint">
                  . Hover for
                  <span class="field-instructions" [tooltip]="options[intakeFields.INTEREST_START_DATE].hint"
                        placement="bottom">
                    more info
                  </span>.
                </span>
            </mat-hint>
            <mat-datepicker-toggle matSuffix [for]="dpInterest"></mat-datepicker-toggle>
            <mat-datepicker #dpInterest></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="in-row-grid"
             *ngIf="showingAtLeastOne(intakeFields.ATTORNEY_FEE, intakeFields.CIVIL_RECOVERY_FEES,
             intakeFields.COSTS, intakeFields.PARTIAL_PAYMENTS)">
          <mat-form-field *ngIf="showField(options[intakeFields.ATTORNEY_FEE])"
                          [ngClass]="{'hidden-from-intake': !!options[intakeFields.ATTORNEY_FEE]?.hide_from_intake}">
            <mat-label>Attorney Fee</mat-label>
            <span matPrefix>$ &nbsp;</span>
            <input matInput type="number" formControlName="attorney_fee"
                   [required]="options[intakeFields.ATTORNEY_FEE].required">
            <mat-error *ngIf="form?.get('attorney_fee').hasError('required')">
              Please enter a value.
            </mat-error>
            <mat-error *ngIf="form?.get('attorney_fee').hasError('pattern')">
              Invalid currency format.
            </mat-error>
            <mat-hint class="whitespace-nowrap">
              {{ options[intakeFields.ATTORNEY_FEE].required ? 'Required' : 'Optional' }}
              <span class="field-instructions-wrapper" *ngIf="options[intakeFields.ATTORNEY_FEE].hint">
                            . Hover for
                            <span class="field-instructions" [tooltip]="options[intakeFields.ATTORNEY_FEE].hint"
                                  placement="bottom">
                              more info
                            </span>.
                          </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field *ngIf="showField(options[intakeFields.CIVIL_RECOVERY_FEES])"
                          [ngClass]="{'hidden-from-intake': !!options[intakeFields.CIVIL_RECOVERY_FEES]?.hide_from_intake}">
            <mat-label>Civil Recovery Fee</mat-label>
            <span matPrefix>$ &nbsp;</span>
            <input matInput type="number" formControlName="civil_recovery_fees"
                   [required]="options[intakeFields.CIVIL_RECOVERY_FEES].required">
            <mat-error *ngIf="form?.get('civil_recovery_fees').hasError('required')">
              Please enter a value.
            </mat-error>
            <mat-error *ngIf="form?.get('civil_recovery_fees').hasError('pattern')">
              Invalid currency format.
            </mat-error>
            <mat-hint class="whitespace-nowrap">
              {{ options[intakeFields.CIVIL_RECOVERY_FEES].required ? 'Required' : 'Optional' }}
              <span class="field-instructions-wrapper" *ngIf="options[intakeFields.CIVIL_RECOVERY_FEES].hint">
                            . Hover for
                            <span class="field-instructions" [tooltip]="options[intakeFields.CIVIL_RECOVERY_FEES].hint"
                                  placement="bottom">
                              more info
                            </span>.
                          </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field *ngIf="showField(options[intakeFields.COSTS])"
                          [ngClass]="{'hidden-from-intake': !!options[intakeFields.COSTS]?.hide_from_intake}">
            <mat-label>Costs</mat-label>
            <span matPrefix>$ &nbsp;</span>
            <input matInput type="number" formControlName="costs"
                   [required]="options[intakeFields.COSTS].required">
            <mat-error *ngIf="form?.get('costs').hasError('required')">
              Please enter a value.
            </mat-error>
            <mat-error *ngIf="form?.get('costs').hasError('pattern')">
              Invalid currency format.
            </mat-error>
            <mat-hint class="whitespace-nowrap">
              {{ options[intakeFields.COSTS].required ? 'Required' : 'Optional' }}
              <span class="field-instructions-wrapper" *ngIf="options[intakeFields.COSTS].hint">
                            . Hover for
                            <span class="field-instructions" [tooltip]="options[intakeFields.COSTS].hint"
                                  placement="bottom">
                              more info
                            </span>.
                          </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field *ngIf="showField(options[intakeFields.PARTIAL_PAYMENTS])"
                          [ngClass]="{'hidden-from-intake': !!options[intakeFields.PARTIAL_PAYMENTS]?.hide_from_intake}">
            <mat-label>Partial Payments</mat-label>
            <span matPrefix>$ &nbsp;</span>
            <input matInput type="number" formControlName="partial_payments"
                   [required]="options[intakeFields.PARTIAL_PAYMENTS].required">
            <mat-error *ngIf="form?.get('partial_payments').hasError('required')">
              Please enter a value.
            </mat-error>
            <mat-error *ngIf="form?.get('partial_payments').hasError('pattern')">
              Invalid currency format.
            </mat-error>
            <mat-hint class="whitespace-nowrap">
              {{ options[intakeFields.PARTIAL_PAYMENTS].required ? 'Required' : 'Optional' }}
              <span class="field-instructions-wrapper" *ngIf="options[intakeFields.PARTIAL_PAYMENTS].hint">
                            . Hover for
                            <span class="field-instructions" [tooltip]="options[intakeFields.PARTIAL_PAYMENTS].hint"
                                  placement="bottom">
                              more info
                            </span>.
                          </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="in-row-grid" *ngIf="claimsSection?.fields?.length && claimsFields">
          <ee-field *ngFor="let f of claimsSection?.fields; let i = index"
                    [ngClass]="{'margin-bottom-sm': i < (claimsSection.fields.length - 1),
                                'md:mb-4': i < (claimsSection.fields.length - 1),
                                'double-column': f.flex === 2, 'triple-column': f.flex === 3, 'quad-column': f.flex === 4}"
                    [hideFromIntake]="f.hide_from_intake"
                    [hidden]="f.hide_from_intake && !overrideFieldVisibility"
                    [control]="$any(claimsFields.controls[f.field])" [field]="f">
          </ee-field>
        </div>
        <div class="in-row-grid" style="display:none !important;">
          <mat-form-field class="compact total" appearance="fill" matTooltip="Calculated field." matTooltipPosition="left">
            <input matInput formControlName="tenant_total_owed" required readonly>
          </mat-form-field>
        </div>
        <div class="triple-column flex flex-col justify-end items-end" *ngIf="form?.get('tenant_total_owed')">
          <div class="total">Total Amount: {{getTotalOutstandingAmount | currency}}</div>
          <mat-error *ngIf="form?.get('tenant_total_owed').errors">
            Total must be greater than 0.
          </mat-error>
        </div>
      </ng-template>
    </div>
    <ng-template #noClaims>
      <div class="flex flex-row justify-center items-center">
        <div class="mt-4 mb-4">Claims are not required.</div>
      </div>
    </ng-template>
  `,
  styles: [`
    @import '../section-styles';
  `]
})
export class ClaimsSectionComponent implements OnChanges, AfterViewChecked, OnDestroy {
  @Input() demoMode = false;

  @Input() overrideFieldVisibility = false;

  @Input() form: UntypedFormGroup;

  @Input() clearOnSwitch = false;

  @Input() options: Map<string, IntakeOption> | undefined;

  @Input() claimsSection: IntakeFormSection | undefined;

  @Input() evictionReason: string | undefined;

  @Input() eviction: Eviction | undefined;

  intakeFields = IntakeFormFields;

  selectedClaimsType = 'simple';

  hideSection = false;

  year = new Date().getFullYear();

  subs: Subscription[] = [];

  constructor(private changeDetector: ChangeDetectorRef,
              private conditionEvaluatorService: ConditionEvaluatorService) {
  }

  ngOnChanges() {
    // this.subs.push(this.form.get('past_due_rent_history').valueChanges.subscribe(() => this.recalculateTotal()));
    this.subs.push(this.form.get('past_due_rent').valueChanges.subscribe(() => this.recalculateTotal()));
    this.subs.push(this.form.get('late_fees').valueChanges.subscribe(() => this.recalculateTotal()));
    this.subs.push(this.form.get('damages').valueChanges.subscribe(() => this.recalculateTotal()));
    this.subs.push(this.form.get('costs').valueChanges.subscribe(() => this.recalculateTotal()));
    this.subs.push(this.form.get('civil_recovery_fees').valueChanges.subscribe(() => this.recalculateTotal()));
    this.subs.push(this.form.get('attorney_fee').valueChanges.subscribe(() => this.recalculateTotal()));
    this.subs.push(this.form.get('partial_payments').valueChanges.subscribe(() => this.recalculateTotal()));
    if (this.claimsFields) {
      this.subs.push(this.claimsFields.valueChanges.subscribe(() => this.recalculateTotal()));
    }

    if ((this.options && this.options[this.intakeFields.CLAIMS].type === 'detailed') ||
      (this.form.controls.past_due_rent.value || this.form.controls.late_fees.value ||
        this.form.controls.damages.value || this.form.controls.costs.value ||
        this.form.controls.civil_recovery_fees.value || this.form.controls.attorney_fee.value ||
        this.form.controls.partial_payments.value)) {
      this.selectedClaimsType = 'detailed';
    }

    this.hideSection = false;
    this.form.enable();
    if (this.claimsSection?.rules?.length) {
      this.claimsSection.rules.forEach((rule) => {
        // calculate the rule
        if(this.conditionEvaluatorService.evaluateRuleDefinition(rule.source_field, rule.operator, rule.value, this.eviction)) {
          if (rule.target_type === RuleTargetType.SECTION) {
            if (rule.action === RuleTargetAction.DISABLE || rule.action === RuleTargetAction.HIDE) {
              this.form.disable();
              this.form.reset();
            }
            if (rule.action === RuleTargetAction.HIDE) {
              this.hideSection = true;
            }
          }
        } else {
          this.form.updateValueAndValidity();
        }
      });
    }
  }

  showField(option: IntakeOption): boolean {
    return option?.show && (!option.hide_from_intake || this.demoMode || this.overrideFieldVisibility);
  }

  showingAtLeastOne(...fields: IntakeFormFields[]): boolean {
    let show = false;
    fields.forEach(f => { show = show || this.showField(this.options[f]); });
    return show;
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  get claimsFields(): UntypedFormGroup | undefined {
    return this.form.get('custom_fields') as UntypedFormGroup;
  }

  get getTotalOutstandingAmount() {
    return this.form.get('tenant_total_owed').value ?? 0;
  }

  updateTotal(value: number) {
    this.form.controls.past_due_rent.setValue(value);
  }

  private recalculateTotal() {
    let total = new Big(0);
    if (this.form.controls.past_due_rent.value) {
      total = total.add(this.form.controls.past_due_rent.value);
    }
    if (this.form.controls.late_fees.value) {
      total = total.add(this.form.controls.late_fees.value);
    }
    if (this.form.controls.damages.value) {
      total = total.add(this.form.controls.damages.value);
    }
    if (this.form.controls.costs.value) {
      total = total.add(this.form.controls.costs.value);
    }
    if (this.form.controls.civil_recovery_fees.value) {
      total = total.add(this.form.controls.civil_recovery_fees.value);
    }
    if (this.form.controls.attorney_fee.value) {
      total = total.add(this.form.controls.attorney_fee.value);
    }
    if (this.form.controls.partial_payments.value) {
      total = total.sub(this.form.controls.partial_payments.value);
    }

    if (this.claimsSection?.fields?.length) {
      this.claimsSection.fields.forEach((f) => {
        if (f.include_in_total && this.claimsFields.contains(f.field)) {
          if (this.claimsFields.get(f.field).value) {
            total = total.add(this.claimsFields.get(f.field).value);
          }
        }
      });
    }

    if (this.options && this.options[this.intakeFields.CLAIMS]?.type === 'simple') {
      total = total.add(this.form.controls.tenant_total_owed.value ?? 0);
    }

    this.form.get('tenant_total_owed').setValue(total.toNumber());
  }

}
