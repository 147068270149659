import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {IntakeFormSection, IntakeOption, Organization} from '@ee/common/models';
import {AccountType} from '@ee/common/enums';

@Component({
  selector: 'ee-eviction-property-details',
  template: `
    <form class="flex flex-col justify-start items-stretch" [formGroup]="propertyDetailsForm">
      <ee-expansion-panel class="mb-2 md:mb-4" header="Plaintiff" [expanded]="true" [collapsible]="false" [fullWidth]="true">
        <mat-form-field class="w-full">
          <mat-label>Plaintiff Name</mat-label>
          <input matInput formControlName="company_name" required
                 [matAutocomplete]="companyNameAutoComplete" [matAutocompleteDisabled]="plaintiffHistory.length < 2">
          <mat-autocomplete #companyNameAutoComplete="matAutocomplete" [autoSelectActiveOption]="true">
            <mat-option *ngFor="let option of plaintiffHistory" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="propertyDetailsForm.get('company_name').hasError('required')">
            A plaintiff is required.
          </mat-error>
          <mat-hint *ngIf="propertyDetailsForm.get('company_name').hasError('required')">
            Required. Typically the name of the management company or property owner.
          </mat-hint>
        </mat-form-field>
      </ee-expansion-panel>
      <ee-expansion-panel class="mb-2 md:mb-4" header="Tenants" [expanded]="true" [collapsible]="false" [fullWidth]="true">
        <div ee-expansion-panel-header-actions>
          <div style="font-size: 14px">Add all the tenants found on the lease</div>
        </div>
        <ee-eviction-tenants-section [form]="tenantDetailsForm" [options]="options" [tenantSection]="tenantSection"
                                     [overrideFieldVisibility]="loggedInOrg?.type === accountTypes.ATTORNEY">
        </ee-eviction-tenants-section>
      </ee-expansion-panel>
      <ee-expansion-panel header="Property Address & Details" [expanded]="true" [collapsible]="false" [fullWidth]="true">
        <div ee-expansion-panel-header-actions>
          <div style="font-size: 14px">Specify the address of the property</div>
        </div>
        <ee-eviction-property-section [form]="propertyDetailsForm" [options]="options" [loggedInOrg]="loggedInOrg"
                                      [overrideFieldVisibility]="loggedInOrg?.type === accountTypes.ATTORNEY"
                                      [propertySection]="propertySection" [selectedClient]="selectedClient">
        </ee-eviction-property-section>
      </ee-expansion-panel>
    </form>
  `,
  styles: [`
    .margin-bottom {
      margin-bottom: 1rem;
    }
    .margin-bottom-sm {
      margin-bottom: .5rem;
    }
  `]
})
export class EvictionPropertyDetailsComponent {

  @Input() tenantDetailsForm: UntypedFormGroup;

  @Input() propertyDetailsForm: UntypedFormGroup;

  @Input() loggedInOrg?: Organization;

  @Input() selectedAttorneyId: string;

  @Input() selectedClient: Organization | undefined;

  @Input() options: Map<string, IntakeOption> | undefined;

  @Input() plaintiffHistory: string[];

  @Input() overrideFieldVisibility = false;

  @Input() propertySection: IntakeFormSection | undefined;

  @Input() tenantSection: IntakeFormSection | undefined;

  accountTypes = AccountType;
}
